import VideoIframe from '../components/VideoIframe'

export default class ModFullBackgroundVideo {
  constructor($el) {
    this.$this = $($el)
  }

  init() {
    if (this.$this.length) {
      const play = new VideoIframe(this.$this, null, null)
      play.init()
    }
  }
}
$(window).on('load', () => {
  if ($(window).innerWidth() > 1200) {
    if ($('.mod-full-background-image-video').length) {
      $('.mod-full-background-image-video').each((i, e) => {
        new ModFullBackgroundVideo(e).init()
      })
    }
  }
})