export const getYoutubeVideoId = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[7].length === 11 ? match[7] : false
}

export const getVimeoVideoId = (url) => {
  const regExp = /https?:\/\/(?:www\.)?(?:player\.)?vimeo(?:\.com|pro)?\/(?:[^\d]+)?(\d+)(?:.*)?/i
  const match = url.match(regExp)
  if (match && match[1]) {
    return match[1]
  }
  return null
}