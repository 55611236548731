export default class Mod3ColumnImage {
  constructor($el) {
    this.$this = $($el)
    this.$slider = this.$this.find('.slider')
  }

  init() {
    const debounce = (func, wait) => {
      let timeout
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout)
          func(...args)
        }
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
      }
    }

    if (this.$this.length) {
      this.addSlick()

      $(window).on('resize', debounce(() => {
        this.addSlick()
      }, 250)) // Adjust the 250ms delay as needed
    }
  }

  addSlick() {
    if ($(window).width() <= 1200 && !this.$slider.hasClass('slick-initialized')) {
      this.$slider.slick({
        slidesToShow: 1,
        adaptiveHeight: false,
        dots: true,
        mobileFirst: true,
        appendArrows: false,
        infinite: true,
        dotsClass: 'slick-dots dots-image',
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      })
    }

    if ($(window).width() > 1200 && this.$slider.hasClass('slick-initialized')) {
      this.$slider.slick('unslick')
    }
  }
}
if ($('.mod-3-column-image').length) {
  $('.mod-3-column-image').each((_i, el) => {
    new Mod3ColumnImage(el).init()
  })

}
