export default class CallBackLazy {
  constructor() {
    this.$html = $('html')
  }

  call(elementTmp, element) {
    if ($(element).hasClass('lazy')) {
      const dataSrc = element.getAttribute('data-src')
      const $pictureTag = $(element).closest('picture')
      if (elementTmp === 'IMG') {
        if ($pictureTag.length) {
          const $sourcePicture = $pictureTag.find('source')
          $sourcePicture.each((_idx, elm) => {
            $(elm).attr('srcset', $(elm).attr('data-srcset')).removeAttr('data-srcset')
          })
        }
        element.setAttribute('src', dataSrc)
      } else {
        $(element).css({
          'background-image': `url('${dataSrc}')`,
        })
      }
      $(element).addClass('b-loaded').removeClass('lazy').removeAttr('data-src').parents('a').find('.alt-text').remove()
    }
  }
}
