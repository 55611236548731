export default class ModHistory {
  constructor($el) {
    this.$this = $($el)
    this.$slider = this.$this.find('.history-slider')
    this.$slick = null
    this.$arrow = this.$this.find('.slick-arrow')
    this.slickCurrent = 'slick-current'
    this.clsSlickActive = '.slick-active'
    this.clsSlickCurrent = `.${this.slickCurrent}`
    this.clicked = false
  }

  init() {
    if (this.$this.length) {
      this.addSlick()
      this.controlClickSlide()
      this.controlSlider()
    }
  }

  controlSlider() {
    this.$arrow.on('click', (e) => {
      if (this.clicked) {
        return
      }
      this.clicked = true
      setTimeout(() => {
        this.clicked = false
      }, 500)
      const $el = $(e.currentTarget)
      const $currentSlide = this.$slider.find(this.clsSlickCurrent)
      const $activeSlides = this.$slider.find(this.clsSlickActive)
      const $lastActiveSlide = $activeSlides.last()
      const $firstActiveSlide = $activeSlides.first()
      if ($el.is('.slick-next')) {
        if ($currentSlide.index() === this.$slider.find('.slick-slide').length - 1) {
          this.loop = true
          this.slick.slick('slickGoTo', 0)
          this.$this.find(this.clsSlickCurrent).removeClass(this.slickCurrent)
          this.$this.find(this.clsSlickActive).first().addClass(this.slickCurrent)
          return
        }
        this.loop = false
        if (!$currentSlide.is($lastActiveSlide)) {
          const $nextSlide = $currentSlide.next(this.clsSlickActive)
          $currentSlide.removeClass(this.slickCurrent)
          $nextSlide.addClass(this.slickCurrent)
          this.setWidthLine()
        } else {
          this.slick.slick('slickNext')
        }

      } else {
        if ($currentSlide.index() === 0) {
          this.loop = true
          this.slick.slick('slickGoTo', this.$slider.find('.slick-slide').length - 1)
          this.$this.find(this.clsSlickCurrent).removeClass(this.slickCurrent)
          this.$this.find(this.clsSlickActive).last().addClass(this.slickCurrent)
          return
        }
        this.loop = false
        if (!$currentSlide.is($firstActiveSlide)) {
          const $prevSlide = $currentSlide.prev(this.clsSlickActive)
          $currentSlide.removeClass(this.slickCurrent)
          $prevSlide.addClass(this.slickCurrent)
          this.setWidthLine()
        } else {
          this.slick.slick('slickPrev')

        }
      }
    })
  }

  controlClickSlide() {
    this.$slider.on('click', '.history-wrapper', (e) => {
      const $el = $(e.currentTarget)
      if ($el.is(this.clsSlickCurrent)) {
        return
      }
      const $slideClicked = $el.parents('.slick-slide')
      this.$slider.find(this.clsSlickCurrent).removeClass(this.slickCurrent)
      $slideClicked.addClass(this.slickCurrent)
      if (!$slideClicked.hasClass('slick-active')) {
        if ($slideClicked.nextAll(this.clsSlickActive).length) {
          this.slick.slick('slickPrev')
        } else {
          this.slick.slick('slickNext')
        }
      } else {
        this.setWidthLine()
      }
    })
  }

  setWidthLine() {
    this.$slider.find('.slick-passed').removeClass('slick-passed')
    setTimeout(() => {
      this.$slider.find('.line-active').css({ 'width': `calc(12px + ${this.$slider.find(this.clsSlickCurrent).index() / this.$slider.find('.slick-slide').length * 100}%)` })
      this.$slider.find(this.clsSlickCurrent).prevAll().addClass('slick-passed')
    }, 20)
  }

  addSlick() {
    this.slick = this.$slider.slick({
      slidesToShow: 4,
      slidesToScroll: 4,
      infinite: false,
      dots: false,
      arrows: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    })
    this.$slider.find('.slick-track').append(`<div class="dot-active-slider absolute top-0 flex items-center inset-x-0">
          <div class="h-12 line-active w-6 bg-secondary-200 rounded-l-[80px] relative">
            <div class="dot-active w-20 h-20 rounded-full bg-secondary-200 flex items-center justify-center -mt-4 -mb-4 -right-10 absolute">
            <div class="w-10 h-10 rounded-full bg-white"></div>
            </div>
          </div>
        </div>`)
    this.slick.on('beforeChange', (_event, _slick, currentSlide, slickNext) => {
      if (this.loop !== true) {
        if (currentSlide > slickNext) {
          setTimeout(() => {
            this.$slider.find(this.clsSlickActive).first().removeClass(this.slickCurrent)
            this.$slider.find(this.clsSlickActive).last().addClass(this.slickCurrent)
          }, 10)
        }
      }
      this.setWidthLine()
    })

  }
}
if ($('.mod-history').length) {
  $('.mod-history').each((_i, el) => {
    new ModHistory(el).init()
  })

}
