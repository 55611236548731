import VideoIframe from '../components/VideoIframe'

export default class ModTextImageVideo {
  constructor(el) {
    this.$this = $(el)
    this.$openVideo = this.$this.find('.js-open-lightbox')
    this.$closeVideo = this.$this.find('.js-close-lightbox')
    this.$wrapVideo = this.$this.find('.image-video-wrapper')
    this.player = null
    this.openLightbox = false
  }

  init() {
    this.controlPopup()
    $(window).on('resize', () => {
      this.setHeightModule()
    })
  }

  setHeightModule() {
    if (window.innerWidth > 992) {
      if (!this.openLightbox) {
        this.$this.height(this.$wrapVideo.height())
        return
      }
      const widthLightbox = window.innerWidth - 34 > 1414 ? 1414 : window.innerWidth - 34
      const heightLightbox = widthLightbox * 9 / 16
      if (this.$wrapVideo.height() < heightLightbox) {
        this.$this.height(heightLightbox)
      }

    } else {
      this.$this.css('height', '')
    }
  }

  controlPopup() {
    this.$openVideo.on('click', () => {
      this.openLightbox = true
      if (this.player === null) {
        const play = new VideoIframe(this.$this, null, null)
        play.init()
        this.$this.addClass('lightbox-show')
      } else {
        this.player.playVideo()
      }
      this.setHeightModule()
      setTimeout(() => {
        this.$this.addClass('show-video')
      }, 500)
    })
    this.$closeVideo.on('click', () => {
      this.openLightbox = false
      this.$this.removeClass('lightbox-show')
      if (this.player) {
        this.player.pauseVideo()
      }
      this.setHeightModule()
      setTimeout(() => {
        this.$this.removeClass('show-video')
      }, 500)
    })
  }
}

if ($('.mod-text-image-video').length) {
  $('.mod-text-image-video').each((i, el) => {
    new ModTextImageVideo(el).init()
  })
}