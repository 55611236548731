export default class ModCarouselPhoto {
  constructor($el) {
    this.$this = $($el)
    this.$sliderFor = this.$this.find('.slider-for')
    this.$sliderNav = this.$this.find('.slider-nav')
    this.$carouselProgress = this.$this.find('.carousel-progress')
    this.speed = 500
    this.speedAuto = 5000
    this.timeAnimation = this.speed + this.speedAuto - 10
    this.timeOut = null
    this.$stopButton = this.$this.find('.stop-carousel-btn')
  }

  init() {
    if (this.$this.length) {
      this.addSlick()
    }
  }

  autoNextSlide(width, nextSlide) {
    clearTimeout(this.timeOut)
    this.$carouselProgress.css({
      'width': `${width * nextSlide}%`,
      'transition': '',
    })
    setTimeout(() => {
      this.$carouselProgress.css({
        'width': `${width * (nextSlide + 1)}%`,
        'transition': `width ${this.timeAnimation}ms linear 0s`,
      })
      this.timeOut = setTimeout(() => {
        this.$sliderFor.slick('slickNext')
      }, this.timeAnimation)
    }, 10)

  }

  addSlick() {
    this.$sliderFor.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      dots: true,
      arrows: false,
      appendDots: this.$this.find('.dots-control'),
      dotsClass: 'slick-dots control-carousel',
      asNavFor: this.$sliderNav,
      speed: this.speed,
    })
    this.$sliderNav.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      asNavFor: this.$sliderFor,
      arrows: false,
      dots: false,
    })
    this.$carouselProgress.css({
      'width': `0%`,
      'transition': `width ${this.timeAnimation}ms linear 0s`,
    })
    const slickLength = this.$sliderFor.find('.slick-slide').length
    const width = 100 / slickLength
    this.autoNextSlide(width, 0)
    this.$sliderFor.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
      this.autoNextSlide(width, nextSlide)
    })

    this.$this[0].addEventListener('stopProgress', () => {
      clearTimeout(this.timeOut)
      this.$sliderFor.slick('slickGoTo', 0)
      this.$carouselProgress.css({
        'width': `0%`,
        'transition': '',
      })
    })
  }
}
if ($('.mod-photo-carousel-text').length) {
  $('.mod-photo-carousel-text').each((_i, el) => {
    new ModCarouselPhoto(el).init()
  })

}
