export default class ModResourceList {
  constructor (el) {
    this.$this = $(el)
    this.$search = this.$this.find('#search')
    this.$btnClearInput = this.$this.find('.btn-clear')
    this.clearing = false
  }

  init() {
    if (this.$this.length) {
      this.activeBtnClearInput()
      this.clearSearch()
    }
  }

  activeBtnClearInput () {
    if (this.$search.length) {
      this.$search.on('keyup', () => {
        const valueSearch = this.$search.val().trim()
        if (valueSearch.length > 0 && valueSearch !== '') {
          this.clearing = true
        } else {
          this.clearing = false
        }
        this.handleChangeBtnClear()
      })
    }
  }

  clearSearch () {
    if (this.$btnClearInput.length) {
      this.$btnClearInput.on('click', () => {
        this.clearing = true
        if (this.$search.val() !== '') {
          this.$search.val('')
        }
        this.clearing = false
        this.handleChangeBtnClear()
      })
    }
  }

  handleChangeBtnClear () {
    if (this.clearing === true) {
      this.$btnClearInput.addClass('active')
    } else {
      this.$btnClearInput.removeClass('active')
    }
    this.clearing = false
  }

}
if ($('.mod-resource-list').length) {
  $('.mod-resource-list').each((i, el) => {
    new ModResourceList(el).init()
  })
}