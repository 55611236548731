import {
  getYoutubeVideoId,
  getVimeoVideoId,
} from '../utils'

export default class VideoIframe {
  constructor(el, mySwiper = null, sliderFor = null) {
    this.$el = $(el)
    this.$video = this.$el.find('.js-background-video-item')
    this.mySwiper = mySwiper
    this.sliderFor = sliderFor
    this.type = null
    this.player = null
    this.play = true
    this.mute = true
    this.nextSlide = true
    this.loaded = false
    this.control = this.$video.attr('data-control') || false
    this.src = this.$video.attr('data-src')
    this.autoplay = this.$video.attr('data-autoplay')
    this.$controlVideo = this.$el.find('.js-control-video')
    this.$controlVideoMute = this.$el.find('.js-control-video-mute')
    this.$iconControl = this.$controlVideo.find('.icon-control')
    this.$iconControlMute = this.$controlVideoMute.find('.icon-control')
    this.$textControl = this.$controlVideo.find('.js-text-control')
    this.$textControlMute = this.$controlVideoMute.find('.js-text-control')
    this.$imgVideo = this.$el.find('.img-bg')
    if (this.src === undefined || this.src === null || this.src === '') {
      return
    }
    this.validateSrc()
  }

  init() {
    if (this.src === undefined || this.src === null || this.src === '') {
      return
    }

    this.runViddeo()
  }

  runViddeo() {
    if (this.type !== null && !this.loaded) {
      this.loaded = true
      if (typeof (window.YT.Player) !== 'undefined') {
        this.observeVideo()
      } else {
        window.onYouTubeIframeAPIReady = () => {
          this.observeVideo()
        }
      }
    }
  }

  validateSrc() {
    const youtubeVideoId = getYoutubeVideoId(this.src)
    const vimeoVideoId = getVimeoVideoId(this.src)
    if (youtubeVideoId) {
      this.type = 'youtube'
      this.id = youtubeVideoId
    } else if (vimeoVideoId) {
      this.type = 'vimeo'
      this.id = vimeoVideoId
    }
  }

  lazyLoadVideo(isInViewport) {
    if (isInViewport && this.player === null) {
      if (this.type === 'youtube') {
        this.player = new window.YT.Player(this.$video[0], {
          videoId: this.id,
          playerVars: {
            autoplay: 1,
            loop: 0,
            mute: 1,
            controls: this.control,
            rel: 0,
            playlist: this.id,
            modestbranding: 0,
            showinfo: 0,
            autohide: 1,
          },
          events: {
            onReady: () => {
              $(this.player.g).attr('aria-hidden', 'true')
            },
            onStateChange: (e) => {
              if (e.data === window.YT.PlayerState.PLAYING) {
                this.hiddenImgWhenPlayVideo(1000)
              }
              if (e.data === window.YT.PlayerState.ENDED) {
                this.nextSliderAfterEndVideo()
                this.player.seekTo(0)
              }
              setTimeout(() => {
                this.nextSlide = true
              }, 100)
            },
          },
        })
      } else if (this.type === 'vimeo') {
        this.player = new window.Vimeo.Player(this.$video[0], {
          id: this.id,
          autoplay: true,
          loop: true,
          muted: true,
          controls: this.control,
        })
        this.player.on('seeked', () => {
          this.nextSlide = false
          this.nextSliderAfterEndVideo()
        })
        this.player.on('playing', () => {
          this.hiddenImgWhenPlayVideo(10)
        })
      }
    }
  }

  nextSliderAfterEndVideo() {
    if (this.sliderFor) {
      this.sliderFor.slick('slickNext')
    }
  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect()
    return (
      rect.top < window.innerHeight && rect.bottom >= 0 &&
      rect.left < window.innerWidth && rect.right >= 0
    )
  }

  loadVideo() {
    const isInViewport = this.isInViewport(this.$el.get(0))
    this.lazyLoadVideo(isInViewport)
  }

  observeVideo() {
    this.loadVideo()
    $(window).on('scroll', () => {
      this.loadVideo()
    })
  }

  eventPlayOrPauseVideoOnDesktop(isInViewport) {
    if (isInViewport) {
      this.playVideo()
    } else {
      this.pauseVideo()
    }
  }

  playVideo() {
    if (!this.play && this.player) {
      if (this.mySwiper && !this.$el.parents('.slick-active') || !this.isInViewport(this.$el.get(0))) {
        return
      }
      if (this.type === 'youtube' && this.player.playVideo) {
        this.player.playVideo()
      } else if (this.type === 'vimeo') {
        this.player.play()
      }
      this.play = true
    }
  }

  pauseVideo() {
    if (this.play && this.player) {
      if (this.player.pauseVideo) {
        this.player.pauseVideo()
      } else if (this.player.pause) {
        this.player.pause()
      }
      this.play = false
    }
  }

  hiddenImgWhenPlayVideo(timeout) {
    setTimeout(() => {
      if (this.$imgVideo.hasClass('opacity-100')) {
        this.$imgVideo.removeClass('opacity-100').addClass('opacity-0')
      }
    }, timeout)
  }
}
