export default class ModShareThis {
  constructor (elm) {
    this.$this = $(elm)
    this.$container = this.$this.find('.container')
    this.$listShare = this.$this.find('.share-blog-post')
  }

  init () {
    if (this.$this.length) {
      this.calculateBoxPosition(this.$listShare)
      $(window).on('scroll resize', () => {
        this.calculateBoxPosition(this.$listShare)
      })
    }
  }

  handleChangePosition ($element, position, top, left, bottom) {
    $element.css('position', position)
    $element.css('top', top)
    $element.css('left', left)
    $element.css('bottom', bottom)
  }

  calculateBoxPosition ($element) {
    if ($(window).innerWidth() > 1200) {
      const space = $('header').height() + 20
      if (window.scrollY + space > this.$container.offset().top) {
        if (window.scrollY > (this.$this.height() + this.$this.offset().top - $element.innerHeight() - 100)) {
          this.handleChangePosition($element, 'absolute', 'unset', 'auto', 0)
        } else {
          this.handleChangePosition($element, 'fixed', space, 'auto', 'unset')
        }
      } else {
        this.handleChangePosition($element, 'absolute', 0, 'auto', 'unset')
      }
    }

    if ($(window).innerWidth() >= 768 && $(window).innerWidth() < 1200) {
      this.handleChangePosition($element, 'absolute', 0, 'auto', 'unset')
    }

    if ($(window).innerWidth() < 768) {
      this.handleChangePosition($element, 'relative', 0, 'auto', 'unset')
    }
  }
}

if ($('.mod-blog-post-content').length) {
  $('.mod-blog-post-content').each((i, el) => {
    new ModShareThis(el).init()
  })
}
