export default class SimpleScroll {
  constructor(el) {
    this.$this = $(el)
    this.container = this.$this.find('.scroll-container')
    this.scrollX = this.$this.data('scroll-x') || false
    this.scrollY = !this.scrollX
    this.loaded = false
  }

  init() {
    this.createScrollbars()
    if (this.loaded) {
      return
    }
    this.loaded = true
    this.syncScroll()
    this.addDragEvents()
    $(window).on('resize', () => this.createScrollbars())
  }

  createScrollbars() {
    this.createScrollbarX()
    this.createScrollbarY()
  }

  createScrollbarX() {
    if (!this.scrollX) {
      this.scrollbarThumbX?.remove()
      this.scrollbarTrackX?.remove()
      this.scrollbarThumbX = null
      this.scrollbarTrackX = null
      return
    }

    const containerWidth = this.container.width()
    const contentWidth = this.container[0].scrollWidth
    if (contentWidth > containerWidth) {
      const scrollbarWidth = containerWidth / contentWidth * containerWidth
      if (!this.scrollbarThumbX) {
        this.scrollbarTrackX = $('<div class="scrollbar-track track-x"></div>').appendTo(this.$this)
        this.scrollbarThumbX = $('<div class="scrollbar-thumb scrollbar-x"></div>').css({
          width: `${scrollbarWidth}px`,
        }).appendTo(this.scrollbarTrackX)
      } else {
        this.scrollbarThumbX.css({ width: `${scrollbarWidth}px` })
      }
    } else {
      this.scrollbarThumbX?.remove()
      this.scrollbarTrackX?.remove()
      this.scrollbarThumbX = null
      this.scrollbarTrackX = null
    }
  }

  createScrollbarY() {
    if (!this.scrollY) {
      this.scrollbarThumbY?.remove()
      this.scrollbarTrackY?.remove()
      this.scrollbarThumbY = null
      this.scrollbarTrackY = null
      return
    }

    const containerHeight = this.container.height()
    const contentHeight = this.container[0].scrollHeight

    if (contentHeight - 2 > containerHeight) {
      const scrollbarHeight = containerHeight / contentHeight * containerHeight
      if (!this.scrollbarThumbY) {
        this.scrollbarTrackY = $('<div class="scrollbar-track track-y"></div>').appendTo(this.$this)
        this.scrollbarThumbY = $('<div class="scrollbar-thumb scrollbar-y"></div>').css({
          height: `${scrollbarHeight}px`,
        }).appendTo(this.scrollbarTrackY)
      } else {
        this.scrollbarThumbY.css({ height: `${scrollbarHeight}px` })
      }
    } else {
      this.scrollbarThumbY?.remove()
      this.scrollbarTrackY?.remove()
      this.scrollbarThumbY = null
      this.scrollbarTrackY = null
    }
  }

  syncScroll() {
    this.container.on('scroll', () => {
      if (this.scrollbarThumbX) {
        const scrollLeft = this.container.scrollLeft()
        const xRatio = scrollLeft / (this.container[0].scrollWidth - this.container.width())
        this.scrollbarThumbX.css({
          left: `${xRatio * (this.scrollbarTrackX.width() - this.scrollbarThumbX.width())}px`,
        })
      }

      if (this.scrollbarThumbY) {
        const scrollTop = this.container.scrollTop()
        const yRatio = scrollTop / (this.container[0].scrollHeight - this.container.height())
        this.scrollbarThumbY.css({
          top: `${yRatio * (this.scrollbarTrackY.height() - this.scrollbarThumbY.height())}px`,
        })
      }
    })
  }

  addDragEvents() {
    this.addDragEventX()
    this.addDragEventY()
  }

  addDragEventX() {
    if (!this.scrollbarThumbX) return
    let startX
    this.scrollbarThumbX.on('mousedown', (e) => {
      startX = e.pageX - this.scrollbarThumbX.position().left
      $(document).on('mousemove.scrollX', this.onDragX.bind(this, startX))
    })

    $(document).on('mouseup.scrollX', () => {
      $(document).off('mousemove.scrollX')
    })
  }

  onDragX(startX, e) {
    const containerWidth = this.container.width()
    const contentWidth = this.container[0].scrollWidth
    const x = e.pageX - startX
    const scrollPos = (x / containerWidth) * contentWidth
    this.container.scrollLeft(scrollPos)
  }

  addDragEventY() {
    if (!this.scrollbarThumbY) return
    let startY

    this.scrollbarThumbY.on('mousedown', (e) => {
      startY = e.pageY - this.scrollbarThumbY.position().top
      $(document).on('mousemove.scrollY', this.onDragY.bind(this, startY))
    })
    $(document).on('mouseup.scrollY', () => {
      $(document).off('mousemove.scrollY')
    })
  }

  onDragY(startY, e) {
    const containerHeight = this.container.height()
    const contentHeight = this.container[0].scrollHeight
    const y = e.pageY - startY
    const scrollPos = (y / containerHeight) * contentHeight
    this.container.scrollTop(scrollPos)
  }
}
if ($('.custom-scroll').length) {
  $('.custom-scroll').each((index, el) => {
    new SimpleScroll(el).init()
  })
}
