import VideoIframe from '../components/VideoIframe'

export default class ModAccordion {
  constructor() {
    this.moduleClass = '.mod-accordion'
    this.$this = $(this.moduleClass)
    this.jsClassExpand = 'js-expand'
    this.openOneClass = '.only-open-one'
    this.closeAllClass = '.close-all-item'
    this.ariaExpanded = 'aria-expanded'
    this.animatingClass = 'animating'
    this.player = null
    this.videoDesktop = $('.js-show-video.show-video-desktop')
    this.videoMobile = $('.js-show-video.show-video-mobile')
    this.$playVideo = $('.js-control-video')
    this.clsActive = 'active'
  }

  handleToggle(elem) {
    const $moduleParents = $(elem).parents(this.moduleClass)
    const $content = this.findContent($(elem))
    const controlsId = $(elem).attr('aria-controls')
    const $otherControl = $moduleParents.find(`[data-controltab="${controlsId}"]`)
    const open = $(elem).attr(this.ariaExpanded).trim() === 'true'
    const isOnlyOpenOne = $moduleParents.is(this.openOneClass)
    const isCloseAllItem = $moduleParents.is(this.closeAllClass)
    const isRow = $moduleParents.is('.js-accordion-row') && window.innerWidth > 991
    const animationDuration = 400
    if (isOnlyOpenOne) {
      if (!open) {
        this.deActivate($moduleParents)
        $(elem).attr(this.ariaExpanded, 'true').addClass(this.jsClassExpand).parents('.js-accordion-item').addClass(this.clsActive)
        if (!isRow) {
          $content.slideDown()
        }
        $moduleParents.find(`[data-controltab`).addClass('hidden')
        $otherControl.removeClass('hidden')
      } else if (isCloseAllItem) {
        $content.slideUp(animationDuration)
        $otherControl.addClass('hidden')
        $(elem).addClass(this.animatingClass).attr(this.ariaExpanded, `${!open}`).toggleClass(this.jsClassExpand).parents('.js-accordion-item').toggleClass(this.clsActive)
      }
    } else {
      if ($(elem).hasClass(this.animatingClass)) {
        return
      }
      if (!open) {
        $content.slideDown(animationDuration)
        $otherControl.removeClass('hidden')
      } else {
        $content.slideUp(animationDuration)
        $otherControl.addClass('hidden')
      }
      $(elem).addClass(this.animatingClass).attr(this.ariaExpanded, `${!open}`).toggleClass(this.jsClassExpand).parents('.js-accordion-item').toggleClass(this.clsActive)

      // prevent multiple click events
      setTimeout(() => {
        $(elem).removeClass(this.animatingClass)
      }, animationDuration + 100)
    }
  }

  activeShowContent(elem) {
    if ($(window).innerWidth() < 992) {
      if (elem.length) {
        const h = $('#header').height() || 0
        $('html, body').stop().animate({
          scrollTop: elem.offset().top - h + 20,
        }, {
          duration: 1100,
          easing: 'swing',
          step: (_now, fx) => {
            if (fx.prop === 'scrollTop') {
              fx.end = elem.offset().top - h - 20
            }
          },
        })
      }
    }
  }

  deActivate(element) {
    const $buttons = element.find('button[aria-expanded]')
    const isRow = element.is('.js-accordion-row') && window.innerWidth > 991
    $buttons.each((_i, elem) => {
      const $elem = $(elem)
      const contentEl = this.findContent($elem)
      $elem.attr('aria-expanded', `false`).removeClass(this.jsClassExpand).parents('.js-accordion-item').removeClass(this.clsActive)
      if (!isRow) {
        contentEl.slideUp()
      }
    })
  }

  findContent(btn) {
    const controlsId = btn.attr('aria-controls')
    return $(`#${controlsId}`)
  }

  addListeners(element) {
    element.find('button[aria-expanded]').each((_i, elem) => {
      $(elem).on('click', (e) => {
        if ($(e.currentTarget).is('.mouse')) {
          this.handleToggle(elem)
          setTimeout(() => {
            this.activeShowContent($(elem))
            this.handleControlVideo(element)
          }, 100)
        }
      })

      $(elem).on('keydown', (e) => {
        this.keydownEventListener(e, elem)
      })
    })
  }

  handleControlVideo(element) {
    element.find('.js-show-video').each((_index, el) => {
      const player = $(el).data('player')
      if (player && player.type !== null) {
        this.setupVideo($(el), $(el).is(':visible'))
      }
    })
  }

  controlVideo() {
    this.$playVideo.on('click', (e) => {
      const $el = $(e.currentTarget)
      $el.addClass('hidden')
      const $videoItem = $el.parents('.js-show-video')
      $videoItem.find('.js-background-video-item').addClass('bg-black-100')
      this.setupVideo($videoItem, true)
    })
  }

  setupVideo($videoActive, play = true) {
    if ($videoActive.data('player')) {
      const player = $videoActive.data('player')
      if (player.type !== null) {
        if (play) {
          player.playVideo()
        } else {
          player.pauseVideo()
        }
      }
      return
    }

    if (play) {
      const player = new VideoIframe($videoActive, null, null)
      $videoActive.data('player', player)
      if (player.type !== null) {
        player.init()
      }
    }
  }

  pauseVideoNotActive() {
    if (this.videoDesktop.length || this.videoMobile.length) {
      this.$this.find('.js-show-video').each((_index, element) => {
        this.setupVideo($(element), false)
      })
    }
  }

  keydownEventListener(event, element) {
    const key = event.keyCode

    switch (key) {
      case 32:
        event.preventDefault()
        this.handleToggle(element)
        break

      case 13:
        event.preventDefault()
        this.handleToggle(element)
        break

      default:
        break
    }
  }

  getWidthActive($moduleParents) {
    const $item = $moduleParents.find('.js-accordion-item:not(.active)')
    const totalWidth = ($item.innerWidth() + 8) * $item.length
    const widthActive = $moduleParents.find('.accordion').outerWidth() - totalWidth
    return widthActive
  }

  setWidthToContentActive($el) {
    if (window.innerWidth > 991) {
      $el.find('.purpose-content').css('width', this.getWidthActive($el))
    } else {
      $el.find('.purpose-content').css('width', '')
    }

  }

  init() {
    if (this.$this.length) {
      this.$this.each((_index, el) => {
        this.addListeners($(el))
        this.setWidthToContentActive($(el))
        $(window).on('resize', () => {
          this.setWidthToContentActive($(el))
        })
      })
    }
    this.controlVideo()
  }
}

new ModAccordion().init()
