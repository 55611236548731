export default class ModFeaturedResouces {
  constructor($el) {
    this.$this = $($el)
    this.$sliderFor = this.$this.find('.slider-for')
    this.$sliderNav = this.$this.find('.slider-nav')
    this.$dots = this.$this.find('.js-dot-item')
    this.$activeDot = this.$this.find('.active-dot')
    this.$customDots = this.$this.find('.custom-dots')
    this.$nextSlide = this.$this.find('.js-next-slide')
    this.indexActive = 0
  }

  init() {
    this.addSlick()
    this.controlSlick()
  }

  controlSlick() {
    this.$dots.click((e) => {
      const index = $(e.currentTarget).attr('data-index')
      this.$sliderFor.slick('slickGoTo', parseInt(index, 10))
    })
    this.$nextSlide.click(() => {
      this.$sliderFor.slick('slickNext')
    })
  }

  setDotsAfterSlide(indexActive) {
    const $currenDot = this.$this.find(`[data-index=${indexActive}]`)

    if ($currenDot.length) {
      const postionCurentDot = $currenDot.offset().left
      const customDotsLeft = this.$customDots.offset().left
      this.$activeDot.css('left', indexActive === 0 ? 0 : postionCurentDot - customDotsLeft - 2)
      let indexDot = 0
      this.$dots.each((i, e) => {
        if (i === indexActive) {
          indexDot++
        }
        if (indexDot <= indexActive) {
          $(e).css('left', -(23))
        } else {
          $(e).css('left', '')
        }
        $(e).attr('data-index', indexDot)
        $(e).find('.dot-positon').text(indexDot)
        indexDot++
      })
    }
  }

  addSlick() {
    if (this.$this.find('.slider-for').length === 0) {
      return
    }
    this.$sliderFor.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      dots: false,
      arrows: false,
      asNavFor: this.$sliderNav,
      speed: this.speed,
    })
    this.$sliderNav.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      asNavFor: this.$sliderFor,
      arrows: false,
      dots: false,
    })
    this.$sliderFor.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
      this.setDotsAfterSlide(nextSlide)
    })
  }
}
if ($('.mod-featured-resouces').length) {
  $('.mod-featured-resouces').each((_i, el) => {
    new ModFeaturedResouces(el).init()
  })

}
