import Pristine from 'pristinejs'

export default class ValidateContactForm7 {
  constructor() {
    this.$this = $('.wpcf7-form')
    this.$header = $('#header')
    this.$formDrag = $('.form-drag-drop')
    this.hagtag = '#thank-you'
    this.formParent = '.form-layout-wrapper'
    this.customResponse = '.wpcf7-custom-response-output'
    this.htmlBody = 'html, body'
    this.disabled = 'disabled'
    this.defaultConfig = {
      classTo: 'form-group',
      errorClass: 'error',
      successClass: 'has-success',
      errorTextParent: 'form-group',
    }
  }

  init() {
    if (this.$this.length) {
      this.$this.each((_i, form) => {
        this.submitForm($(form))
      })
      this.getTokenAndSubmitForm()
      this.initPlaceholder()
    }
  }

  initPlaceholder() {
    const selector = 'option-disabled'
    $(`[${selector}]`).each((_i, e) => {
      const $ele = $(e)
      $ele.find(`select option[value=${$ele.attr(selector)}]`).attr('value', '')
    })
  }

  handleChangeClassForm(element) {
    if (element.length > 0 && element.hasClass(this.defaultConfig.errorClass)) {
      element.removeClass(this.defaultConfig.errorClass).addClass(this.defaultConfig.successClass)
    }
  }

  validatePhone($form, pristine) {
    $form.find('[type=tel]').each((_i, tel) => {
      pristine.addValidator(tel, () => {
        const required = $(tel).attr('required') === true
        return !required && $(tel).val() !== ''
      }, 'This field is required', 1, true)
    })
  }

  checkEmail(value) {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailRegex.test(value)
  }

  validateEmail($form, pristine) {
    $form.find('[type=email]').each((_i, email) => {
      pristine.addValidator(email, () => {
        const required = $(email).attr('required') === true
        return this.checkEmail($(email).val()) && required || (!required && $(email).val() !== '' ? this.checkEmail($(email).val()) : true)
      }, 'The email address entered is invalid', 2, true)
    })
  }

  changeStatusSelect() {
    const selectC8 = $('.select-c8')
    selectC8.on('change', () => {
      if (selectC8.val() !== '') {
        this.handleChangeClassForm(selectC8.closest('.form-group'))
      }
    })
  }

  getTokenAndSubmitForm() {
    // eslint-disable-next-line
    if (!$('.html-home').length || typeof wpcf7_recaptcha === 'undefined') {
      return
    }
    if (typeof grecaptcha !== 'undefined') {
      // eslint-disable-next-line
      grecaptcha.ready(() => {
        setInterval(() => {
          // eslint-disable-next-line
          grecaptcha.execute(wpcf7_recaptcha.sitekey, { action: 'form_submission' })
            .then((token) => {
              $('.g-recaptcha-response').val(token)
              $(`form.wpcf7-form input[name='_wpcf7_recaptcha_response']`).val(token)

            })
        }, 90000)
        // eslint-disable-next-line
        grecaptcha.execute(wpcf7_recaptcha.sitekey, { action: 'form_submission' })
          .then((token) => {
            $('.g-recaptcha-response').val(token)
            $(`form.wpcf7-form input[name='_wpcf7_recaptcha_response']`).val(token)
          })
      })
    } else {
      requestAnimationFrame(() => {
        this.getTokenAndSubmitForm()
      })

    }

  }

  checkFormIsValid($form, pristine) {
    return pristine.validate()
  }

  submitForm($form) {
    let disableSubmit = false
    $form.find('[aria-required="true"]').attr('required', true)
    $form.find('.wpcf7-validates-as-required input').attr('required', true)
    const pristine = new Pristine(
      $form.get(0),
      this.defaultConfig,
      true,
    )
    this.validateEmail($form, pristine)
    this.validatePhone($form, pristine)

    $form.find('.submit, .wpcf7-submit').on('click', (e) => {
      const isValid = this.checkFormIsValid($form, pristine)
      if (!isValid || this.$this.hasClass('submitting')) {
        if (!isValid) {
          this.focusFirstError($form)
        }
        e.preventDefault()
      } else {
        if (disableSubmit) {
          return false
        }

        disableSubmit = true
        return true
      }
      return true
    })

    $form.get(0).addEventListener('wpcf7_before_send_mail', () => {
      disableSubmit = false
    }, false)

    $form.get(0).addEventListener('wpcf7invalid', () => {
      disableSubmit = false
    }, false)
    $form.get(0).addEventListener('wpcf7submit', () => {
      // eslint-disable-next-line no-unused-expressions
      this.checkFormIsValid($form, pristine) ? this.handleSubmit($form) : $form.find('input').focus()
    }, false)

    $form.get(0).addEventListener('wpcf7mailsent', () => {
      $form.addClass('submited')
      $(this.hideSuccessfully).hide()
      const $scrollTo = $('.scroll-to')
      $('.hide-after-submit, .fieldset-cf7adn').hide()
      $('.loading-icon').hide()
      if ($scrollTo.length) {
        this.scrollOnTopSection($scrollTo)
      } else {
        this.scrollOnTopSection($form, -150)
      }
    }, false)
  }

  scrollOnTopSection(el, customTopHeight = 0) {
    if (el.length) {
      const offsetEl = el.offset()
      let headerHeight = 0
      if (this.$header.length) {
        headerHeight = this.$header.innerHeight()
      }

      $('html,body').animate({
        scrollTop: offsetEl.top - headerHeight + customTopHeight,
      }, 700)
    }
  }

  focusFirstError(el) {
    if (el && el.length) {
      const errors = el.find('.error.form-group, .error-val .form-group, ._has_error, .error, .wpcf7-not-valid')
      if (errors && errors.length) {
        this.scrollOnTopSection($(errors[0]), -150)
        setTimeout(() => {
          $(errors[0]).focus()
          $(errors[0]).find('textarea, input').focus()
        }, 350)
      }
    }
  }

  hagtagHandle() {
    const currentURL = window.location.origin + window.location.pathname
    const hagtag = '#thank-you'
    const urlSlug = currentURL + hagtag
    window.history.replaceState(null, null, urlSlug)
  }

  handleSubmit($form) {
    $('.loading-icon').hide()
    setTimeout(() => {
      if ($form.find('.form-layout').length) {
        $form.find('.form-layout').hide()
        $('.form-headline').hide()
      }
      if ($form.parents('.form-layout').length) {
        $form.parents('.form-layout').hide()
        $('.form-headline').hide()
      }
      if ($form.parents(this.formParent).find(this.customResponse).length) {
        $form.parents(this.formParent).find(this.customResponse).show()
        $form.find('.wpcf7-response-output').hide()
      } else {
        $form.find('.wpcf7-response-output').show()
      }
    }, 50)
    this.hagtagHandle()
  }

}

new ValidateContactForm7().init()
