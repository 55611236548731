export default class ModFilter {
  constructor($el) {
    this.$this = $($el)
    this.$dropdown = this.$this.find('.select-c8')
    this.$pageNumber = this.$this.find('.page-numbers')
    this.$searchInput = this.$this.find('#keyword')
    this.$baseURL = this.$this.find('#base-url')
    this.filterParams = []
    this.clearing = false
  }

  init() {
    if (this.$this.length) {
      this.showClearButton()
      this.controlFilterBar()
    }
  }

  showClearButton() {
    const urlParams = new URLSearchParams(window.location.search)
    const hasPage = urlParams.has('page')
    const paramsCount = Array.from(urlParams.keys()).length

    if ((hasPage && paramsCount > 1) || (!hasPage && paramsCount > 0)) {
      $('.btn-clear').show()
    } else {
      $('.btn-clear').hide()
    }
  }

  controlFilterBar() {
    this.$dropdown.on('change', (e) => {
      e.preventDefault()
      if (!this.clearing) {
        this.updateFilterParams(1, this.$searchInput.val())
      }
    })

    this.$this.on('click', '.page-numbers', (e) => {
      e.preventDefault()
      if (!this.clearing) {
        this.updateFilterParams($(e.currentTarget).data('page'), this.$searchInput.val())
      }
    })

    this.$this.on('click', '.submit-search', (e) => {
      e.preventDefault()
      if (!this.clearing) {
        this.updateFilterParams(1, this.$searchInput.val())
      }
    })

    this.$this.on('keydown', '#keyword', (e) => {
      if (e.keyCode === 13 && !this.clearing) {
        e.preventDefault()
        this.updateFilterParams(1, this.$searchInput.val())
      }
    })

    this.$this.on('click', '.btn-clear, .btn-clear-selection', (e) => {
      e.preventDefault()
      if (!this.clearing) {
        this.clearAllFilters()
      }
    })
  }

  updateFilterParams(pageNumber = 1, keyword = '') {
    this.searchParams = new URLSearchParams('')
    const baseUrl = this.$baseURL.val()
    this.filterParams = []
    if (pageNumber > 1) {
      this.filterParams.push({ key: 'page', value: pageNumber })
      this.searchParams.set('page', pageNumber)
    }

    if (keyword) {
      this.filterParams.push({ key: 'keyword', value: keyword })
      this.searchParams.set('keyword', keyword)
    }

    if (baseUrl) {
      this.filterParams.push({ key: 'base_url', value: baseUrl })
    }

    this.$dropdown.each((_i, el) => {
      const $el = $(el)
      const key = $el.attr('id')
      const value = $el.val()
      if (key !== null && value !== 'all') {
        this.filterParams.push({ key, value })
        this.searchParams.set(key, value)
      }
    })

    const newUrl = this.searchParams.toString()
      ? `${window.location.pathname}?${this.searchParams.toString()}`
      : window.location.pathname

    window.history.pushState(null, '', newUrl)
    this.callAjax(this.filterParams)
  }

  clearAllFilters() {
    this.clearing = true
    window.history.pushState(null, '', window.location.pathname)
    this.$this.find('.dropdown-menu-c8').each((i, e) => {
      $(e).find('li').eq(0).trigger('click')
      $(e).prev('.dropdown-toggle').attr('aria-expanded', false)
    })
    setTimeout(() => {
      this.clearing = false
      this.$searchInput.val('')
      this.filterParams = []
      this.callAjax(this.filterParams)
    }, 200)
  }

  callAjax(filterParams) {
    if (this.clearing) {
      return
    }
    const action = this.$this.find('#filter-result').data('action')
    $.ajax({
      type: 'POST',
      url: window.AJAX_URL, // eslint-disable-line
      dataType: 'text',
      data: { action, filterParams },
      beforeSend() {
        $('.filter-loading').fadeIn(500)
      },
      success: (response) => {
        $('#filter-result').html(response)
        this.showClearButton()
        $('html, body').stop().animate(
          {
            scrollTop: this.$this.offset().top - $('.header').height() - 20,
          })
      },
      complete() {
        $('.filter-loading').fadeOut(500)
      },
    })
  }
}

if ($('.mod-filter').length) {
  $('.mod-filter').each((_i, el) => {
    new ModFilter(el).init()
  })
}
